/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'update': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.407 14.383c-3.516 2.286-6.596.435-6.596.435s-.482.498-.902.918c-.42.451-.607.296-.669-.249a582.25 582.25 0 00-.283-3.21.437.437 0 01.435-.477h2.96c.637 0 .933.156.357.762-.404.467-.824.965-.824.965s2.395.902 4.667-.653c1.649-1.12 1.913-3.423 1.913-3.89 0-.45.202-.653.638-.653h.42c.42 0 .638.233.638.653 0 .42-.078 3.656-2.754 5.399zM9.452 5.438c.404-.467.824-.965.824-.965s-2.396-.902-4.667.653C3.96 6.246 3.696 8.55 3.696 9.016c0 .45-.203.653-.638.653h-.42c-.42 0-.638-.233-.638-.653 0-.405.078-3.64 2.753-5.399 3.516-2.302 6.596-.435 6.596-.435s.483-.498.903-.918c.42-.451.606-.295.669.249.078.944.214 2.447.283 3.21a.437.437 0 01-.435.477h-2.96c-.638 0-.933-.156-.357-.762z" _fill="#8261FC"/>'
  }
})
